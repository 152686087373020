<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12 v-if="storeTQRole">
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item }">
                        <tr :id="'r-' + item.ID">
                            <td>{{ item.TransactionCode }}</td>
                            <td v-if="weightEditable && item.Status != package_status_enum.DA_XUAT_VN">
                                <v-edit-dialog :return-value.sync="item.ProductType" large lazy persistent @save="saveItem({'ID': item.ID, 'ProductType': item.ProductType})">
                                    <div>{{ item.ProductType | display_value(product_type_list) }}</div>
                                    <template v-slot:input>
                                        <v-select v-model="item.ProductType" :items="product_type_list" item-text="name" item-value="value" label="Loại hàng" single-line autofocus></v-select>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td v-else>{{ item.ProductType | display_value(product_type_list) }}</td>
                            <td>{{ item.Username }}</td>
                            <td>{{ item.PackageCode }}</td>
                            <td class="text-right">{{ item.Weight | currency('', 2) }}</td>
                            <td class="text-right">{{ item.Volume | currency('', 3) }}</td>
                            <td class="text-right">{{ item.RealNumberOfPack | currency }}</td>
                            <td class="text-right">{{ item.TotalWeight | currency('', 2) }}</td>
                            <td class="text-right">{{ item.TotalVolume | currency('', 3) }}</td>
                            <td>{{ item.StoreCNImportDate | display_date }}</td>
                            <td>{{ item.PackDate | display_date }}</td>
                            <td>{{ item.StoreCNExportDate | display_date }}</td>
                            <td>{{ item.StoreVNImportDate | display_date }}</td>
                            <td class="text-right" v-if="item.Status != package_status_enum.DA_XUAT_VN">
                                <v-edit-dialog :return-value.sync="item.FeeShipVND" large lazy persistent @save="saveItem({'ID': item.ID, 'FeeShipVND': item.FeeShipVND})">
                                    <div>{{ item.FeeShipVND | currency }}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="item.FeeShipVND" type="number" step="1" label="Phí khác" single-line autofocus></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right" v-else>{{ item.FeeShipVND | currency('') }}</td>
                            <td class="truncate">
                                <v-tooltip top max-width="250">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">{{ item.StoreCNNote }}</span>
                                    </template>
                                    <span>{{ item.StoreCNNote }}</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:body.append v-if="summary">
                        <tr class="total-summary-td">
                            <td :colspan="4" class="text-center font-weight-bold">Tổng</td>
                            <td class="text-end font-weight-bold">{{ summary.Weight | currency('', 2) }}</td>
                            <td class="text-end font-weight-bold">{{ summary.Volume | currency('', 3) }}</td>
                            <td class="text-end font-weight-bold">{{ summary.RealNumberOfPack | currency('') }}</td>
                            <td class="text-end font-weight-bold">{{ summary.TotalWeight | currency('', 2) }}</td>
                            <td class="text-end font-weight-bold">{{ summary.TotalVolume | currency('', 3) }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="text-end font-weight-bold">{{ summary.FeeShipVND | currency('') }}</td>
                            <td></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
        <v-flex lg12 v-else-if="storeVNRole">
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item }">
                        <tr :id="'r-' + item.ID">
                            <td>{{ item.TransactionCode }}</td>
                            <td v-if="weightEditable && item.Status != package_status_enum.DA_XUAT_VN">
                                <v-edit-dialog :return-value.sync="item.ProductType" large lazy persistent @save="saveItem({'ID': item.ID, 'ProductType': item.ProductType})">
                                    <div>{{ item.ProductType | display_value(product_type_list) }}</div>
                                    <template v-slot:input>
                                        <v-select v-model="item.ProductType" :items="product_type_list" item-text="name" item-value="value" label="Loại hàng" single-line autofocus></v-select>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td v-else>{{ item.ProductType | display_value(product_type_list) }}</td>
                            <td>{{ item.Username }}</td>
                            <td>{{ item.PackageCode }}</td>
                            <td>{{ item.ReceiptID }}</td>
                            <td>{{ item.StoreVNImportDate | display_date }}</td>
                            <td>{{ item.StoreVNExportDate | display_date }}</td>
                            <td class="text-right" v-if="weightEditable">
                                <v-edit-dialog :return-value.sync="item.Weight" large lazy persistent @save="saveItem({'ID': item.ID, 'Weight': item.Weight})">
                                <div>{{ item.Weight }}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.Weight" type="number" step=".1" label="Cân nặng" :disabled="item.Status == package_status_enum.DA_XUAT_VN" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right" v-if="!weightEditable">{{ item.Weight | currency('', 1) }}</td>
                            <td class="text-right" v-if="weightEditable">
                                <v-edit-dialog large lazy persistent @save="saveItem({ID: item.ID, Length: item.Length, Width: item.Width, Height: item.Height})">
                                <div>
                                    {{ item.Volume | currency('', 3) }}
                                    <v-divider></v-divider>
                                    {{ item.Length | currency('', 1)}} x {{ item.Width | currency('', 1)}} x {{ item.Height | currency('', 1)}}
                                </div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.Length" type="number" step=".1" label="Dài (cm)" single-line autofocus></v-text-field>
                                    <v-text-field v-model="item.Width" type="number" step=".1" label="Rộng (cm)" single-line autofocus></v-text-field>
                                    <v-text-field v-model="item.Height" type="number" step=".1" label="Cao (cm)" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right" v-else>{{ item.Volume | currency('', 3) }}</td>
                            <td class="text-right" v-if="weightEditable">
                                <v-edit-dialog large lazy persistent @save="saveItem({ID: item.ID, RealNumberOfPack: item.RealNumberOfPack})">
                                <div>
                                    {{ item.RealNumberOfPack | currency }}
                                </div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.RealNumberOfPack" type="number" step="1" label="Số lượng" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right" v-else>{{ item.RealNumberOfPack | currency }}</td>
                            <td class="text-right">{{ item.TotalWeight | currency('', 2) }}</td>
                            <td class="text-right">{{ item.TotalVolume | currency('', 3) }}</td>
                            <td class="text-right" v-if="item.Status != package_status_enum.DA_XUAT_VN">
                                <v-edit-dialog :return-value.sync="item.FeeShipVND" large lazy persistent @save="saveItem({'ID': item.ID, 'FeeShipVND': item.FeeShipVND})">
                                    <div>{{ item.FeeShipVND | currency }}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="item.FeeShipVND" type="number" step="1" label="Phí khác" single-line autofocus></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right" v-else>{{ item.FeeShipVND | currency('') }}</td>
                            <td v-bind:style="{ 'color': get_status_color(item.Status, package_status)}">{{ item.Status | display_value(package_status) }}</td>
                            <td class="truncate">
                                <v-tooltip top max-width="250">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">{{ item.StoreVNNote }}</span>
                                    </template>
                                    <span>{{ item.StoreVNNote }}</span>
                                </v-tooltip>
                            </td>
                            <td class="text-center">
                                <!-- <v-icon medium class="mr-2" color="red darken-2" @click="printTransaction(item.Weight, item.WeightByVolume, item.Length, item.Width, item.Height, item.TransactionCode, item.Username, item.OrderID, item.NumberOfCode)">fa-print</v-icon> -->
                            </td>
                        </tr>
                    </template>
                    <template v-slot:body.append v-if="summary">
                        <tr class="total-summary-td">
                            <td :colspan="7" class="text-center font-weight-bold">Tổng</td>
                            <td class="text-end font-weight-bold">{{ summary.Weight | currency('', 2) }}</td>
                            <td class="text-end font-weight-bold">{{ summary.Volume | currency('', 3) }}</td>
                            <td class="text-end font-weight-bold">{{ summary.RealNumberOfPack | currency }}</td>
                            <td class="text-end font-weight-bold">{{ summary.TotalWeight | currency('', 2) }}</td>
                            <td class="text-end font-weight-bold">{{ summary.TotalVolume | currency('', 3) }}</td>
                            <td class="text-end font-weight-bold">{{ summary.FeeShipVND | currency }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
        <v-flex lg12 v-else>
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item }">
                        <tr :id="'r-' + item.ID">
                            <td>{{ item.TransactionCode }}</td>
                            <td v-if="(weightEditable && item.Status != package_status_enum.DA_XUAT_VN) || adminRoles || paymentRole">
                                <v-edit-dialog :return-value.sync="item.ProductType" large lazy persistent @save="saveItem({'ID': item.ID, 'ProductType': item.ProductType})">
                                    <div>{{ item.ProductType | display_value(product_type_list) }}</div>
                                    <template v-slot:input>
                                        <v-select v-model="item.ProductType" :items="product_type_list" item-text="name" item-value="value" label="Loại hàng" single-line autofocus></v-select>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td v-else>{{ item.ProductType | display_value(product_type_list) }}</td>
                            <td>{{ item.Username }}</td>
                            <td>{{ item.PackageCode }}</td>
                            <td>{{ item.ReceiptID }}</td>
                            <td>{{ item.StoreCNImportDate | display_date }}</td>
                            <td>{{ item.PackDate | display_date }}</td>
                            <td>{{ item.StoreCNExportDate | display_date }}</td>
                            <td>{{ item.StoreVNImportDate | display_date }}</td>
                            <td>{{ item.StoreVNExportDate | display_date }}</td>
                            <td class="text-right" v-if="(item.Status != package_status_enum.DA_XUAT_VN) || adminRoles || paymentRole">
                                <v-edit-dialog :return-value.sync="item.FeeShipVND" large lazy persistent @save="saveItem({'ID': item.ID, 'FeeShipVND': item.FeeShipVND})">
                                    <div>{{ item.FeeShipVND | currency }}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="item.FeeShipVND" type="number" step="1" label="Phí khác" single-line autofocus></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right" v-else>{{ item.FeeShipVND | currency }}</td>
                            <td class="text-right" v-if="(weightEditable && item.Status != package_status_enum.DA_XUAT_VN) || adminRoles || paymentRole">
                                <v-edit-dialog :return-value.sync="item.Weight" large lazy persistent @save="saveItem({'ID': item.ID, 'Weight': item.Weight})">
                                <div>{{ item.Weight | currency('', 2) }}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.Weight" type="number" step=".1" label="Cân nặng" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right" v-else>{{ item.Weight | currency('', 2) }}</td>
                            <td class="text-right" v-if="(weightEditable && item.Status != package_status_enum.DA_XUAT_VN) || adminRoles || paymentRole">
                                <v-edit-dialog large lazy persistent @save="saveItem({ID: item.ID, Length: item.Length, Width: item.Width, Height: item.Height})">
                                <div>
                                    {{ item.Volume | currency('', 3) }}
                                    <v-divider></v-divider>
                                    {{ item.Length | currency('', 1)}} x {{ item.Width | currency('', 1)}} x {{ item.Height | currency('', 1)}}
                                </div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.Length" type="number" step=".1" label="Dài (cm)" single-line autofocus></v-text-field>
                                    <v-text-field v-model="item.Width" type="number" step=".1" label="Rộng (cm)" single-line autofocus></v-text-field>
                                    <v-text-field v-model="item.Height" type="number" step=".1" label="Cao (cm)" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right" v-else>{{ item.Volume | currency('', 3) }}</td>
                            <td class="text-right" v-if="(weightEditable && item.Status != package_status_enum.DA_XUAT_VN) || adminRoles || paymentRole">
                                <v-edit-dialog large lazy persistent @save="saveItem({ID: item.ID, RealNumberOfPack: item.RealNumberOfPack})">
                                <div>
                                    {{ item.RealNumberOfPack | currency }}
                                </div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.RealNumberOfPack" type="number" step="1" label="Số lượng" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right" v-else>{{ item.RealNumberOfPack | currency }}</td>
                            <td class="text-right">{{ item.TotalWeight | currency('', 2) }}</td>
                            <td class="text-right">{{ item.TotalVolume | currency('', 3) }}</td>
                            <td class="text-right">{{ item.FeeType | display_value(fee_type_enum) }}</td>
                            <td class="text-right" v-if="(weightEditable && item.Status != package_status_enum.DA_XUAT_VN) || adminRoles || paymentRole">
                                <v-edit-dialog :return-value.sync="item.WeightPrice" large lazy persistent @save="saveItem({'ID': item.ID, 'WeightPrice': item.WeightPrice})">
                                    <div>{{ item.WeightPrice | currency }}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="item.WeightPrice" type="number" step="1" label="Giá cân" single-line autofocus></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right" v-else>{{ item.WeightPrice | currency }}</td>
                            <td class="text-right" v-if="(weightEditable && item.Status != package_status_enum.DA_XUAT_VN) || adminRoles || paymentRole">
                                <v-edit-dialog :return-value.sync="item.VolumePrice" large lazy persistent @save="saveItem({'ID': item.ID, 'VolumePrice': item.VolumePrice})">
                                <div>{{ item.VolumePrice | currency }}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.VolumePrice" type="number" step="1" label="Giá khối" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right" v-else>{{ item.VolumePrice | currency }}</td>
                            <td class="text-right" v-if="(weightEditable && item.Status != package_status_enum.DA_XUAT_VN) || adminRoles || paymentRole">
                                <v-edit-dialog :return-value.sync="item.WeightCost" large lazy persistent @save="saveItem({'ID': item.ID, 'WeightCost': item.WeightCost})">
                                    <div>{{ item.WeightCost | currency }}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="item.WeightCost" type="number" step="1" label="Giá gốc cân" single-line autofocus></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right" v-else>{{ item.WeightCost | currency }}</td>
                            <td class="text-right" v-if="(weightEditable && item.Status != package_status_enum.DA_XUAT_VN) || adminRoles || paymentRole">
                                <v-edit-dialog :return-value.sync="item.VolumeCost" large lazy persistent @save="saveItem({'ID': item.ID, 'VolumeCost': item.VolumeCost})">
                                <div>{{ item.VolumeCost | currency }}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.VolumeCost" type="number" step="1" label="Giá gốc khối" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right" v-else>{{ item.VolumeCost | currency }}</td>
                            <td class="text-right">{{ item.TotalAmount | currency }}</td>
                            <td class="text-right">{{ item.TotalCost | currency }}</td>
                            <td class="text-right">{{ item.TotalProfit | currency }}</td>
                            <td v-bind:style="{ 'color': get_status_color(item.Status, package_status)}">{{ item.Status | display_value(package_status) }}</td>
                            <td class="text-center">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" medium @click="openSplitPackageDialog(item.ID)" class="pa-1" v-bind:style="{ 'color': item.Packages && item.Packages.length > 0 ? '#dc3545' : 'gray'}">widgets</v-icon>
                                    </template>
                                    <span>Danh sách kiện</span>
                                </v-tooltip>
                                <v-icon v-if="adminRoles && item.Status < package_status_enum.DA_VE_VN" small class="ma-1" color="red darken-2" @click="deleteItem(item)">fa-trash-alt</v-icon>
                                <!-- <v-icon medium class="ma-1" color="red darken-2" @click="printTransaction(item.Weight, item.WeightByVolume, item.Length, item.Width, item.Height, item.TransactionCode, item.Username, item.OrderID, item.NumberOfCode)">fa-print</v-icon> -->
                            </td>
                        </tr>
                    </template>
                    <template v-slot:body.append v-if="summary">
                        <tr class="total-summary-td">
                            <td :colspan="10" class="text-center font-weight-bold">Tổng</td>
                            <td class="text-end font-weight-bold">{{ summary.FeeShipVND | currency('', 2) }}</td>
                            <td class="text-end font-weight-bold">{{ summary.Weight | currency('', 2) }}</td>
                            <td class="text-end font-weight-bold">{{ summary.Volume | currency('', 3) }}</td>
                            <td class="text-end font-weight-bold">{{ summary.RealNumberOfPack | currency }}</td>
                            <td class="text-end font-weight-bold">{{ summary.TotalWeight | currency('', 2) }}</td>
                            <td class="text-end font-weight-bold">{{ summary.TotalVolume | currency('', 3) }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="text-end font-weight-bold">{{ summary.TotalAmount | currency }}</td>
                            <td class="text-end font-weight-bold">{{ summary.TotalCost | currency }}</td>
                            <td class="text-end font-weight-bold">{{ summary.TotalProfit | currency }}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
        <v-dialog v-model="dialogImport" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
            <v-toolbar dark color="oee-theme">
                <v-btn icon dark @click="dialogImport = false">
                <v-icon>fa-times-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title>{{importStep == 0 ? 'Import thông tin kiện' : 'Xác minh dữ liệu'}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-container grid-list-md>
                <v-layout row wrap>
                    <!-- <v-flex lg6 md6 sm12 pt-3 v-if="importStep == 0">
                    <v-autocomplete label="K. hàng" v-model="importUID" :items="customers" item-text="FullName" item-value="ID"></v-autocomplete>
                    </v-flex> -->
                    <v-flex lg12 md12 sm12 xs12 pt-3 v-if="importStep == 0">
                    <a href="/package_import_template.xls">File mẫu</a>
                    <v-textarea outlined rows='30' label="Copy dữ liệu từ excel" v-model="dataImport"></v-textarea>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12 pt-3 v-else>
                    <v-data-table fixed-header height="750px"   :headers="importHeaders" :items="importItems" :items-per-page="15" class="elevation-1"></v-data-table>
                    </v-flex>
                </v-layout>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1 white--text" @click="importPreview()" v-if="importStep == 0">Tiếp theo</v-btn>
                <v-btn color="blue darken-1 white--text" @click="importStep = 0" v-if="importStep == 1">Quay lại</v-btn>
                <v-btn color="red darken-1 white--text" @click="importTransaction" v-if="importStep == 1">Nhập dữ liệu</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        <split-package
            :dialogSplitPackage="dialogSplitPackage"
            :readOnly="!adminRoles"
            :currentItem="currentItem"
            v-on:closePopup="dialogSplitPackage = false"
        ></split-package>
        <v-overlay :value="appLoading || loading" z-index="999999">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-layout>
</v-container>
</template>

<script>
import {
    mapState
} from "vuex";
import configs from "@/configs";
import Filtering from "@/components/Filtering";
import { print_transaction } from "@/commons/hpod";
import split_package from "@/components/popups/split_package";
import Vue from 'vue';
import _ from "lodash";

export default {
    components: {
        "hpo-filter": Filtering,
        "split-package": split_package
    },
    data() {
        return {
            dialogSplitPackage: false,
            currentItemID: 0,
            isDepositOrder: configs.SYSTEM_TYPE != 'order',
            show_quick_import_option: configs.SYSTEM_CONFIG.quick_store_import,
            package_status: configs.PACKAGE_STATUS,
            package_status_enum: configs.PACKAGE_STATUS_ENUM,
            fee_type_enum: configs.FEE_TYPE_LIST,
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "transactioncode_list",
            filterConditions: [],
            quickFilterConditions: [],
            dialogImport: false,
            dataImport: '',      
            importUID: 0,
            importStep: 0,
            appLoading: false,
            importHeaders: [
                {
                    text: "Mã kiện",
                    value: "TransactionCode"
                },
                {
                    text: "Loại hàng",
                    value: "ProductType"
                },
                {
                    text: "K. hàng",
                    value: "Username"
                },
                {
                    text: "Ngày nhập kho TQ",
                    value: "StoreCNImportDate"
                },
                {
                    text: "Cân nặng",
                    value: "ChinaWeight"
                },
                {
                    text: "Chiều dài (cm)",
                    value: "ChinaLength"
                },
                {
                    text: "Chiều rộng (cm)",
                    value: "ChinaWidth"
                },
                {
                    text: "Chiều cao (cm)",
                    value: "ChinaHeight"
                },
                {
                    text: "Số lượng",
                    value: "RealNumberOfPack"
                },
                {
                    text: "Giá cân",
                    value: "WeightPrice"
                },
                {
                    text: "Giá khối",
                    value: "VolumePrice"
                },
                {
                    text: "Ship nội địa",
                    value: "FeeShip"
                },
                {
                    text: "Gia cố",
                    value: "FeeFragileGoods"
                },
                {
                    text: "Phí khác",
                    value: "FeeOther"
                }
            ],
            importItems: []
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
        configuration: {
            handler: function (val, oldVal) {
                let header_item = _.find(this.headers, { value: "ProductType" });
                if (header_item) {
                    header_item.values = this.product_type_list;
                }
            },
            deep: true
        }
    },
    computed: {
        ...mapState({
            items: state => state.orderTransaction.all.data,
            summary: state => state.orderTransaction.summary.data,
            total_rows: state => state.orderTransaction.all.total,
            loading: state => state.orderTransaction.loading,
            currentItem: state => state.orderTransaction.selected,
            customers: state => state.customer.master.data,
            configuration: state => state.configuration.selected
        }),
        headers() {
            if (this.storeTQRole) {
                return [
                    {
                        text: "Mã bill",
                        value: "TransactionCode",
                        filterable: true,
                        quickSearch: true,
                        dataType: configs.DATA_TYPE["String"]
                    },
                    {
                        text: "Loại hàng",
                        value: "ProductType",
                        filterable: true,
                        values: [],
                        dataType: configs.DATA_TYPE["Enum"]
                    },
                    {
                        text: "K. hàng",
                        value: "Username",
                        filterable: true,
                        quickSearch: true,
                        dataType: configs.DATA_TYPE["String"]
                    },
                    {
                        text: "Mã bao",
                        value: "PackageCode",
                        filterable: true,
                        dataType: configs.DATA_TYPE["String"]
                    },
                    {
                        text: "Cân nặng",
                        value: "Weight",
                        filterable: true,
                        dataType: configs.DATA_TYPE["Number"]
                    },
                    {
                        text: "Khối",
                        value: "Volume",
                        filterable: true,
                        dataType: configs.DATA_TYPE["Number"]
                    },
                    {
                        text: "Số lượng",
                        value: "RealNumberOfPack",
                        filterable: true,
                        align: "end",
                        dataType: configs.DATA_TYPE["Number"]
                    },
                    {
                        text: "Tổng cân",
                        value: "TotalWeight",
                        filterable: true,
                        align: "end",
                        dataType: configs.DATA_TYPE["Number"]
                    },
                    {
                        text: "Tổng khối",
                        value: "TotalVolume",
                        filterable: true,
                        align: "end",
                        dataType: configs.DATA_TYPE["Number"]
                    },
                    {
                        text: "Nhập kho TQ",
                        value: "StoreCNImportDate",
                        filterable: true,
                        dataType: configs.DATA_TYPE["Date"]
                    },
                    {
                        text: "Đóng bao",
                        value: "PackDate",
                        filterable: true,
                        dataType: configs.DATA_TYPE["Date"]
                    },
                    {
                        text: "Xuất kho TQ",
                        value: "StoreCNExportDate",
                        filterable: true,
                        dataType: configs.DATA_TYPE["Date"]
                    },
                    {
                        text: "Nhập kho VN",
                        value: "StoreVNImportDate",
                        filterable: true,
                        dataType: configs.DATA_TYPE["Date"]
                    },
                    {
                        text: "Phí khác",
                        value: "FeeShipVND",
                        filterable: true,
                        align: "end",
                        dataType: configs.DATA_TYPE["Number"]
                    },
                    {
                        text: "Ghi chú",
                        value: "StoreCNNote",
                        filterable: true,
                        dataType: configs.DATA_TYPE["String"]
                    }
                ];
            }
            if (this.storeVNRole) {
                return [
                    {
                        text: "Mã bill",
                        value: "TransactionCode",
                        filterable: true,
                        quickSearch: true,
                        dataType: configs.DATA_TYPE["String"]
                    },
                    {
                        text: "Loại hàng",
                        value: "ProductType",
                        filterable: true,
                        values: [],
                        dataType: configs.DATA_TYPE["Enum"]
                    },
                    {
                        text: "K. hàng",
                        value: "Username",
                        quickSearch: true,
                        filterable: true,
                        dataType: configs.DATA_TYPE["String"]
                    },
                    {
                        text: "Mã bao",
                        value: "PackageCode",
                        filterable: true,
                        dataType: configs.DATA_TYPE["String"]
                    },
                    {
                        text: "Phiếu XK",
                        value: "ReceiptID",
                        filterable: true,
                        dataType: configs.DATA_TYPE["Number"]
                    },
                    {
                        text: "Nhập kho VN",
                        value: "StoreVNImportDate",
                        filterable: true,
                        dataType: configs.DATA_TYPE["Date"]
                    },
                    {
                        text: "Xuất kho VN",
                        value: "StoreVNExportDate",
                        filterable: true,
                        dataType: configs.DATA_TYPE["Date"]
                    },
                    {
                        text: "Cân nặng",
                        value: "Weight",
                        filterable: true,
                        dataType: configs.DATA_TYPE["Number"]
                    },
                    {
                        text: "Khối",
                        value: "Volume",
                        filterable: true,
                        dataType: configs.DATA_TYPE["Number"]
                    },
                    {
                        text: "Số lượng",
                        value: "RealNumberOfPack",
                        filterable: true,
                        align: "end",
                        dataType: configs.DATA_TYPE["Number"]
                    },
                    {
                        text: "Tổng cân",
                        value: "TotalWeight",
                        filterable: true,
                        align: "end",
                        dataType: configs.DATA_TYPE["Number"]
                    },
                    {
                        text: "Tổng khối",
                        value: "TotalVolume",
                        filterable: true,
                        align: "end",
                        dataType: configs.DATA_TYPE["Number"]
                    },
                    {
                        text: "Phí khác",
                        value: "FeeShipVND",
                        filterable: true,
                        align: "end",
                        dataType: configs.DATA_TYPE["Number"]
                    },
                    {
                        text: "Trạng thái",
                        value: "Status",
                        filterable: true,
                        dataType: configs.DATA_TYPE["Enum"],
                        values: configs.PACKAGE_STATUS
                    },
                    {
                        text: "Ghi chú",
                        value: "StoreVNNote",
                        filterable: true,
                        dataType: configs.DATA_TYPE["String"]
                    },
                    {
                        text: "Thao tác",
                        value: "",
                        align: "center",
                        sortable: false,
                        filterable: false
                    }
                ];
            }
            return [
                {
                    text: "Mã bill",
                    value: "TransactionCode",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Loại hàng",
                    value: "ProductType",
                    filterable: true,
                    values: [],
                    dataType: configs.DATA_TYPE["Enum"]
                },
                {
                    text: "K. hàng",
                    value: "Username",
                    quickSearch: true,
                    filterable: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Mã bao",
                    value: "PackageCode",
                    filterable: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Phiếu XK",
                    value: "ReceiptID",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Nhập kho TQ",
                    value: "StoreCNImportDate",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Date"]
                },
                {
                    text: "Ngày đóng bao",
                    value: "PackDate",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Date"]
                },
                {
                    text: "Xuất kho TQ",
                    value: "StoreCNExportDate",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Date"]
                },
                {
                    text: "Nhập kho VN",
                    value: "StoreVNImportDate",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Date"]
                },
                {
                    text: "Xuất kho VN",
                    value: "StoreVNExportDate",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Date"]
                },
                {
                    text: "Phí khác",
                    value: "FeeShipVND",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Cân nặng",
                    value: "Weight",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Khối",
                    value: "Volume",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Số lượng",
                    value: "RealNumberOfPack",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Tổng cân",
                    value: "TotalWeight",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Tổng khối",
                    value: "TotalVolume",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Cách tính",
                    value: "FeeType",
                    filterable: true,
                    align: "end",
                    values: this.fee_type_enum,
                    dataType: configs.DATA_TYPE["Enum"]
                },
                {
                    text: "Giá cân",
                    value: "WeightPrice",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Giá khối",
                    value: "VolumePrice",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Giá gốc cân",
                    value: "WeightCost",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Giá gốc khối",
                    value: "VolumeCost",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Tổng tiền",
                    value: "TotalAmount",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Tổng giá gốc",
                    value: "TotalCost",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Lợi nhuận",
                    value: "TotalProfit",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Trạng thái",
                    value: "Status",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Enum"],
                    values: configs.PACKAGE_STATUS
                },
                {
                    text: "Thao tác",
                    value: "",
                    align: "center",
                    sortable: false,
                    filterable: false
                }
            ];
        },
        adminRoles() {
            return ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Manager) == configs.ROLE_TYPE.Manager);
        },
        paymentRole() {
            return ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment);
        },
        storeVNRole() {
            return (this.$store.state.authentication.user.roletype == configs.ROLE_TYPE.StoreVN);
        },
        storeTQRole() {
            return this.$store.state.authentication.user.roletype == configs.ROLE_TYPE.StoreTQ;
        },
        weightEditable() {
            return ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreVN) == configs.ROLE_TYPE.StoreVN) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase);
        },
        warehousing() {
            return ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreTQ) == configs.ROLE_TYPE.StoreTQ);
        },
        moduleRole() {
            return this.$store.state.authentication.role.Module;
        },
        filter_param() {
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            return param;
        },
        product_type_list() {
            return this.configuration.ProductTypes ? JSON.parse(this.configuration.ProductTypes) : [];
        },
    },
    methods: {
        get_status_color(val, enums) {
            return this.$options.filters.display_color(val, enums);
        },
        printTransaction(weight, weight_v2, length, width, height, transactionCode, customerName, orderId, numberOfCode) {
            print_transaction(weight, weight_v2, length, width, height, transactionCode, customerName, orderId, numberOfCode);
        },
        saveItem:_.debounce(
        function(item) {
            var data = {
                ...item
            };
            data.Type = "transaction";
            data.ModifiedBy = this.$store.state.authentication.user.username;
            if(data.Weight > 0) {
                item.Status = data.Status = configs.PACKAGE_STATUS_ENUM.DA_VE_VN;
            }
            delete data.CreatedDate;
            delete data.CreatedBy;
            this.$store.dispatch("orderTransaction/postOrderTransaction", data);
        }, 500, {leading: false, trailing: true}),
        // Only admin can view table, staff need search by code
        filter_data: function () {            
            this.$store.dispatch("orderTransaction/getList", this.filter_param);
            this.$store.dispatch("orderTransaction/getSummary", this.filter_param);
            this.getCustomerList();
            this.appLoading = false;
            if(this.currentItemID > 0) {
                this.$store.dispatch("orderTransaction/getDetail", this.currentItemID);
            }
        },
        doWarehousing(tran_code) {
            this.$store.dispatch("storeManager/setDetail", {
                data: {'TransactionCode': tran_code},
                id: 0
            });
        },
        gotoDetail: function (type, id, uid) {
            if (type) {
                let routeData = this.$router.resolve({
                    name: "DepositDetail",
                    params: {
                        Pid: id,
                        title: `Chi tiết đơn hàng (#${id})`
                    },
                    query: {
                        'uid': uid
                    }
                });
                window.open(routeData.href, "_blank");
            } else {
                let routeData = this.$router.resolve({
                    name: "OrderDetail",
                    params: {
                        Pid: id,
                        title: `Chi tiết đơn hàng (#${id})`
                    }
                });
                window.open(routeData.href, "_blank");
            }
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        getCustomerList() {
            this.$store.dispatch("customer/getCustomerList", {
                is_master: 1,
                fields: ["ID", "Username", "CustomerCode"]
            });
        },
        importData() {
            this.dataImport = '';
            this.importItems = [];
            this.dialogImport = true;
        },
        importPreview(){
            this.importItems = [];
            let rows = this.dataImport.split("\n");
            if(rows.length <= 1){
                sendErrorNotice('Không tìm thấy dữ liệu import.')
                return;
            }
            let header = rows[0].split("\t");
            let props = [];
            for (let i = 0; i < header.length; i++) {
                let element = _.find(this.importHeaders, function (item) {
                    return item.text.toLowerCase() === header[i].toLowerCase();
                });
                props.push(element ? element.value : 'unknown');
            }

            if(!props.includes('TransactionCode')) {
                sendErrorNotice('Không tìm thấy dữ liệu import.')
                return;
            }
            this.importStep = 1;
            for (let i = 1; i < rows.length; i++) {
                let item = rows[i].split("\t");
                if(item.length == 0 || item[0] == '') {
                    continue;
                }
                let importItem = {};
                for (let j = 0; j < props.length; j++) {
                    let prop = props[j];
                    if(prop !== 'unknown') {
                        let val = item[j].replace(/\s+/g, ' ').trim();
                        if(val != '') {
                            importItem[prop] = val;
                        }
                    }
                }
                this.importItems.push(importItem);
            }
        },
        importTransaction() {
            this.importItems.forEach(element => {
                if(element.Username) {
                    let conf = _.find(this.customers, function (item) {
                        let ret = this.isDepositOrder ? item.CustomerCode.normalize('NFD').toLowerCase() === element.Username.normalize('NFD').toLowerCase() : item.Username.normalize('NFD').toLowerCase() === element.Username.normalize('NFD').toLowerCase();
                        return ret;
                    });
                    if(conf) {
                        element.UID = conf.ID;
                    }
                    else {
                        delete element.UID;
                    }
                }
                if(element.ProductType) {
                    let conf = _.find(this.product_type_list, function (item) {
                        let ret = item.name.normalize('NFD').toLowerCase() === element.ProductType.normalize('NFD').toLowerCase();
                        return ret;
                    });
                    if(conf) {
                        element.ProductType = conf.value;
                    }
                    else {
                        delete element.ProductType;
                    }
                }
                if(this.importUID > 0) {
                    element.UID = this.importUID;
                }
            });
            this.appLoading = true;
            this.$store.dispatch("orderTransaction/import", {'Transactions': this.importItems});
            this.dialogImport = false;
            this.importStep = 0;
            this.importUID = 0;
        },
        exportExcel() {
            this.$store.dispatch("orderTransaction/export", this.filter_param);
        },
        openSplitPackageDialog(id) {
            this.currentItemID = id;
            this.$store.dispatch("orderTransaction/getDetail", id);
            this.dialogSplitPackage = true;
        },
        deleteItem(item) {
            window.getApp.$emit('REQUEST_DELETE_DATA', item.ID);
        },
    },
    mounted() {
        this.$store.dispatch("configuration/getDetail", 1);
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$on("REQUEST_CREATE_DATA", this.exportExcel);
        this.$root.$on("REQUEST_SAVE_DATA", this.importData);
        this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
            this.$store.dispatch("orderTransaction/removeDetail", {
                'id': id
            });
            setTimeout(this.filter_data, 200);
        });
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$off("REQUEST_CREATE_DATA", this.exportExcel);
        this.$root.$off("REQUEST_SAVE_DATA", this.importData);
        this.$root.$off("CONFIRMED_REMOVE_DATA");
    }
};
</script>
